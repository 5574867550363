.product-description-heading {
    font-size: 19px;
    position: relative;
    margin: 28px 0 0;
    display: table;
    padding-right: 18px;
    background: #eee;
}

.product-description-heading-hr {
    border-color: #ffc004;
    margin: -10px 0 22px;
}

.product-description-heading:after {
    content: '';
    display: block;
    width: 9px;
    height: 9px;
    background: #ffc004;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -9px;
}

.col-thumbnails {
    width: 13%;
}

.product-thumbnail-item {
    width: 95px;
    height: 95px;
    margin-bottom: 9px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    background: #fff;
}

.product-thumbnail-item-image {
    position: absolute;
    top: 50%;
    left: 50%;
    right: unset;
    bottom: unset;
    margin: unset;
    transform: translate(-50%, -50%);
    max-width: 90%;
    max-height: 90%;
}

.product-thumbnail-video:after {
    content: '\f04b';
    font-family: FontAwesome;
    font-size: 30px;
    color: #FFC004;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.col-photo {
    width: 43.8%;
}

.product-photo-main {
    width: 94.6%;
    height: 519px;
}

.product-image-main {
    position: absolute;
    top: 50%;
    left: 50%;
    right: unset;
    bottom: unset;
    margin: unset;
    transform: translate(-50%, -50%);
    background: white;
}

.col-descrptn {
    width: 43.2%;
}

.product-title {
    margin-bottom: 5px;
    font-size: 36px;
}

.product-store-supplier {
    margin-bottom: 60px;
    font-size: 14px;
}

.product-price small {
    font-size: 16px;
    line-height: 1.3;
}

.product-price small.product-price-promo-old {
    display: inline-block !important;
}

.product-price-current {
    font-size: 37px;
    margin: 0;
}

.product-price-current span{
    font-size: 16px;
    line-height: 1.3;
    color: #000;
    font-weight: 400;
}

.product-price small.product-business-model {
    padding: 3px 0;
    width: 75px;
    margin: 22px 0 15px;
    font-size: 12px;
    border-radius: 10px;
}

.variant-content {
    margin-top: 44px;
}

.variant-select-wrapper + .variant-select-wrapper {
    margin-top: 0;
}

.no-variant {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}

.variant-select-label {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    color: #444;
}

.variant-select-label.has-variant {
    margin-bottom: 5px;
}

.variant-select {
    height: 37px;
    padding: 0 20px;
    font-size: 14px !important;
    min-width: 141px;
}

.number-select {
    margin: 0 29px 0 25px;
}

.number-select.no-variant {
    margin-left: 0;
}

.number-select input {
    min-width: 64px;
    width: 64px;
}

.product-buy-action {
    margin-top: 35px;
    width: 312px;
    height: 61px;
    font-size: 34px;
    padding-bottom: 3px;
}

.product-buy-action img {
    margin-left: 15px;
}

.product-payment {
    margin-left: 18px;
    margin-top: 30px;
}

.product-share {
    margin-top: 36px;
    font-size: 0;
}

.product-share-hd {
    font-size: 15px;
    margin: 0 18px 0 0;
}

.product-share li {
    height: 36px;
    width: 36px;
}

.product-shipping-panel {
    margin-bottom: 0;
    margin-top: 35px;
}

.product-showcase {
    margin: 0 30px;
    width: calc(100% - 60px);
}

.product-showcase button.owl-prev {
    left: -30px;
}

.product-showcase button.owl-next {
    right: -30px;
}

.product-showcase .owl-item {
    padding: 0 10px;
}

.product-showcase img {
    width: auto !important;
}

.product-question-answer {
    margin-bottom: 60px;
}

.product-question-unauthorized {
    font-size: 14px;
}

.product__infos--titles {
    position: relative;
    margin: -1px 0 -14px;
    display: table;
    background: #eee;
}

.product__infos {
    margin: 40px 0 0;
}

.product__infos--titles span:first-child {
    margin-right: 26px;
}

.product__infos--title {
    font-weight: 500;
    color: #FFC004;
    margin-right: 20px;
}

.product__infos--titles:after {
    content: '';
    display: block;
    width: 9px;
    height: 9px;
    background: #ffc004;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -9px;
}