.stores-content {
    background: #fff;
    padding: 24px 0 26px;
    margin-top: 10px;
}

#stores-carousel {
    position: relative;
    padding: 0 35px;
}

#stores-carousel .owl-nav {
    display: block !important;
    height: 45px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
}

#stores-carousel .owl-nav button.owl-prev,
#stores-carousel .owl-nav button.owl-next {
    width: 45px;
    height: 45px;
    display: block;
    background-repeat: no-repeat !important;
    background-size: 100% !important;
    background-position: center !important;
    pointer-events: all;
    position: absolute;
    top: 0;
}

#stores-carousel .owl-nav button.owl-prev {
    left: 0;
    background-image: url(../../../../img/icon/soencalho/prev.png) !important;
}

#stores-carousel .owl-nav button.owl-next {
    right: 0;
    background-image: url(../../../../img/icon/soencalho/next.png) !important;
}

#stores-carousel .owl-nav button span {
    color: transparent;
    font-size: 0;
}

#stores-carousel .owl-item {
    padding: 0 25px;
}

#stores-carousel .owl-item a {
    position: relative;
    width: 100%;
    height: 66px;
}

#stores-carousel .owl-item a img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}