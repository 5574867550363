.content-footer-top-area {
    padding: 45px 0;
    font-size: 0;
    text-align: justify;
}

.content-footer-top-area:after {
    content: '';
    display: inline-block;
    width: 100%;
}

.footer-heading {
    line-height: 1;
    margin-bottom: 24px;
    font-size: 17px;
}

.footer-heading span {
    font-size: 16px;
}

.footer-menu-list-item-link {
    font-size: 13px;
}

.security-list {
    margin: 19px -4px 0;
}

.security-list li:first-child {
    margin-right: 22px;
}

.phone-number {
    font-weight: 700;
    font-size: 16px;
}

.social-list {
    margin-top: 22px;
}

.social-list li a i {
    font-size: 26px;
    color: #fff !important;
    margin-right: 13px;
}

.footer-bottom-area {
    padding: 10px 0;
}

.content-footer-bottom-area {
    height: 20px;
    text-align: justify;
    overflow: hidden;
}

.content-footer-bottom-area:after {
    content: '';
    display: inline-block;
    width: 100%;
}

.footer-marketplace-info {
    line-height: 15px;
    font-size: 12px;
}

.store-admin-footer-link {
    font-family: Montserrat;
    font-weight: 700;
    color: #000;
    font-size: 12px;
}

.store-admin-footer-link img {
    margin-right: 4px;
}