.col-filters {
    width: 21%;
    padding-right: 18px;
}

.col-showcase {
    width: 79%;
}

.area-1-category {
    margin-top: 7px;
}

.area-1-category a {
    margin-bottom: 28px;
}

.area-1-category a img,
.area-2-category a img {
    width: 100%;
}

.area-2-category {
    margin: 4px 0 30px;
}