.bradcrumb-content {
    padding: 13px 0 20px;
}

.breadcrumb-list li {
    float: left;
}

.breadcrumb-list li,
.breadcrumb-list li a {
    font-family: Montserrat;
    color: #000;
    font-weight: 400;
    font-size: 14px;
}

.breadcrumb-list li.first {
    text-transform: uppercase;
    margin-right: 3px;
    color: #000;
    font-weight: 600;
}

.breadcrumb-list li:nth-child(n+3):before {
    content: '>';
    line-height: 2.1;
    float: left;
    margin: 2px 4px 0 4px;
    font-size: 8px;
    -webkit-transform: scale(1, 1.5);
    transform: scale(1, 1.5);
}

span.d-none {
    display: none;
}

.sort-by {
    float: left;
    margin: 0;
}

.sort-by__select {
    background: transparent;
}

.sort-by__select:after {
    content: '\f078';
    font-family: FontAwesome;
    font-size: 9px;
    color: #000;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

.sort-by__select select.sort-by__options {
    width: 166px;
    height: 32px;
    color: #000;
    background: #fff;
    border-radius: 17px;
    font-family: Montserrat;
    font-size: 12px !important;
    font-weight: 400;
    padding: 0 15px 2px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.bradcrumb-content label {
    font-family: Montserrat;
    color: #000;
    font-weight: 400;
    font-size: 12.5px;
    float: left;
    margin: 6px 10px 0 33px;
}

a.change-column-item {
    text-decoration: none;
}

.breadcrumb-list {
    margin: 6px 0;
}

.change-column {
    float: left;
}

.filter {
    background: transparent;
}