.header-top {
    font-size: 0;
    padding: 20px 0 12px;
    height: 82px;
    text-align: justify;
}

.header-top:after {
    content: '';
    display: inline-block;
    width: 100%;
}

.header-logo img {
    max-width: 258px;
    max-height: 20px;
}

.user-link img {
    margin-right: 4px;
}

.user-link {
    font-family: Montserrat;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
}

.cart-link {
    margin-left: 26px;
}

.header-search-form {
    width: calc(100% - 258px - 180px - 72px - 58px);
    height: 50px;
    position: relative;
}

.header-search-input {
    width: 100%;
    height: 100%;
    padding: 0 55px 0 30px;
    font-size: 14px !important;
}

.header-search-button {
    width: 18px;
    height: 18px;
    background: transparent;
    background-image: url(../../../../img/icon/soencalho/search.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    border: 0;
    position: absolute;
    top: 50%;
    right: 22px;
    transform: translateY(-50%);
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
}