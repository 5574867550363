.first-level {
    padding-bottom: 12px;
    font-size: 0;
    text-align: justify;
}

.first-level:after {
    content: '';
    display: inline-block;
    width: 100%;
}

.menu-first-level-item {
    padding: 8px 18px;
}

.category-icon-content {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
}

.menu-first-level-item-text,
.menu-first-level-item-text:hover {
    font-family: Montserrat;
    color: #fff !important;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
    font-weight: 500;
}

.sticky .category-icon-content{
    display: none;
}