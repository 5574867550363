.change-column-span {
    width: 9.8px;
    background: #B5B6B3;
    border-radius: 5px;
}

.change-column-item:hover span,
#column-item-active span {
    background: #F4B804;
}

/* .col-sm-6 */
.col-sm-6 .product-box-image-link {
    height: 375px !important;
}

/* .col-sm-4 */
.col-sm-4 .product-box-image-link {
    height: 325px !important;
}

/* .col-sm-3 */
.col-sm-3 .product-box-image-link {
    height: 275px !important;
}

/* .col-sm-25 */
.col-sm-25 .product-box-image-link {
    height: 225px !important;
}
