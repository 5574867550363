.store-banner {
    width: 100%;
}

.store-logo {
    margin: 40px auto 12px;
    width: 116px;
    height: 116px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    background: #fff;
}

.store-logo img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.store-title {
    line-height: 1;
    font-family: Montserrat;
    text-transform: uppercase;
    text-align: center;
    font-size: 15px;
    color: #000;
}

.store-description {
    font-family: Montserrat;
    font-size: 13px;
    color: #000;
    text-align: justify;
    font-weight: 400;
}