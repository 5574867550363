.newsletter {
    background: #000;
}

.newsletter-main {
    padding: 22px 6.5%;
    border-bottom: 1px solid #DEDEDE;
    font-size: 0;
}

.footer__top-area__heading {
    font-size: 20px;
    margin-bottom: 0;
    margin-right: 36px;
}

.newsletter-form-content {
    width: calc(100% - 301px);
}

.newsletter-form {
    width: 100%;
}

.footer-newsletter__button {
    width: 171px;
    height: 56px;
    font-size: 18px;
    margin-left: 19px;
}

.footer-newsletter__input {
    width: calc(100% - 171px - 19px);
    height: 56px;
    padding: 0 18px;
    font-size: 15px !important;
}

.newsletter-form-content {
    position: relative;
}

p#message-newsletter {
    display: block;
    color: #fff !important;
    font-size: 10px;
    position: absolute;
    top: 102%;
    left: calc(50% - (192px / 2));
    transform: translateX(-50%);
    margin-bottom: 0;
}