.products-section.row {
    margin-right: -7.5px;
    margin-left: -7.5px;
}

.products-section div[class*="col-"] {
    padding-right: 7.5px;
    padding-left: 7.5px;
}

.product-box,
.product-box-showcase {
    padding: 7px 7px 23px;
    margin-bottom: 20px;
}

.product-box-image-link {
    margin-bottom: 7px;
}

.product-box-link {
    font-size: 13px;
}

.product-box-title {
    font-size: 13px;
    margin-bottom: 15px;
    line-height: 1.2;
    height: 46px;
    overflow: hidden;
    padding: 0 12px;
}

.product-box-price {
    padding: 0 12px;
}

.spotlight-product-price {
    margin-bottom: 1px;
    font-size: 20px;
}

.product-box-price small {
    font-size: 13px;
}

.product-box-price small.product-box-business-model {
    margin: 10px auto;
    padding: 2px 7px;
    font-size: 10px;
}

.spotlight-product-price.no-discount {
    margin-top: 14px;
}