#banner-1-primary {
    position: relative;
}

#banner-1-primary .owl-dots {
    display: block !important;
}

#banner-1-primary .owl-dots {
    display: block !important;
    position: absolute;
    bottom: 7px;
    left: 50%;
    transform: translateX(-50%);
}

#banner-1-primary .owl-dots .owl-dot {
    margin: 0 3.5px;
}

#banner-1-primary .owl-dots .owl-dot span {
    width: 7px;
    height: 7px;
    background: #fff;
}

#banner-1-primary .owl-dots .owl-dot.active span {
    background: #FFC004;
}

.advantages {
    font-size: 0;
    text-align: justify;
    margin-top: 20px;
    background: #FFC004;
    padding: 21px 3.6% 20px 4%;
    border-radius: 50px;
}

.advantages:after {
    content: '';
    display: inline-block;
    width: 100%;
}

.separator-adv {
    width: 1px;
    height: 30px;
    background: #FFDB6F;
}

.advantages img {
    margin-right: 22px;
}

.advantages span {
    font-family: Montserrat;
    color: #000;
    font-size: 17px;
    font-weight: 700;
    line-height: 1.2;
}

.advantages span small {
    display: block;
    font-weight: 300;
    font-size: 14px;
}

.products-section-hd {
    font-size: 19px;
    position: relative;
    margin: 28px 0 0;
    padding-right: 18px;
    background: #eee;
}

.products-section-hr {
    margin: -10px 0 22px;
}

.products-section-hd:after {
    content: '';
    display: block;
    width: 9px;
    height: 9px;
    background: #ffc004;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -9px;
}

.area-2, .area-3, .area-5 {
    margin-top: 10px;
}

.area-5 {
    margin-bottom: 10px;
}

.area-2, .area-2 a, .area-2 a img,
.area-5, .area-5 a, .area-5 a img {
    width: 100%;
}

.area-3, .area-3 a img {
    width: 100%;
}

.area-3 a {
    width: calc((100% - 26px) / 2);
}